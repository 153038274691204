import React from "react"

const TraditionalSection = () => {
  return (
    <section
      id="status"
      className="stat-wrapper"
      style={{
        padding: "3rem 0",
      }}
    >
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-10 mx-auto mb-4">
            <h2 className="font-weight-medium">
              Traditional Business Application Development Is Expensive
            </h2>
          </div>
          <div className="col-lg-4 col-md-4 stat-box-col">
            <div className="stat-box box-1 mx-auto">
              <h5 className="font-weight-bold mb-0 mt-0">
                6+
                <br />
                Months
              </h5>
            </div>
            <p className="text-uppercase mt-4" style={{ color: "#f25e30" }}>
              Average time to build custom application
            </p>
          </div>
          <div className="col-lg-4 col-md-4 stat-box-col">
            <div className="stat-box box-2 mx-auto">
              <h5 className="font-weight-bold mb-0 mt-0">$300,000+</h5>
            </div>
            <p className="text-uppercase mt-4" style={{ color: "#791f87" }}>
              Average Cost to Develop Complex Applications
            </p>
          </div>
          <div className="col-lg-4 col-md-4 stat-box-col">
            <div className="stat-box box-3 mx-auto">
              <h5 className="font-weight-bold mb-0 mt-0">$3.61</h5>
            </div>
            <p className="text-uppercase mt-4" style={{ color: "#d69900" }}>
              cost of technical debt per line of code in legacy applications
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TraditionalSection
